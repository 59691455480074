h1, h2, h3, p {
  margin: 0px;
  padding: 0px;
}

h1,p {
  transition: all .2s ease-in-out;
}

@media only screen and (min-width: 768px) {
  .App {
    display: flex;
    width: 100vw;
    height: 100vh;
  }

  .backGround {
    background: radial-gradient(82.11% 50% at 82.11% 50%, #FF2A2A 0%, #FFB86A 100%);
    opacity: 0.4;
    filter: blur(76.1119px);
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
  }

  .bodyContainer {
    width: 50%;
    z-index: 2000;
  }

  .textContainer {
    padding: 25px 20px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .headerTitle {
    margin-right: 15px;
  }

  .headerForm {
    margin-right: 15px;
  }

  .headerForm__input {
    padding: 5px 10px;
    border: none;
    background-color: antiquewhite;
    border-bottom: 1px solid #FF4646;
    margin-right: 15px;
    color: black;
    transition: all .2s ease-in-out;
  }

  .headerForm__input:focus {
    outline: none;
    border-color: #ff0f0f;
  }

  .headerForm__button {
    padding: 10px 25px;
    background-color: #FF4646;
    border: none;
    border-radius: 25px;
    font-weight: 600;
    color: white;
  }

  .button {
    border-radius: 25px;
    padding: 10px 25px;
    background-color: #FF4646;
    transition: all .2s ease-in-out;
    cursor: pointer;
    color: white;
  }

  .button:hover {
    color: whitesmoke;
    background-color: #ff0f0f;
  }

  .infoContainer {
    padding: 50px 50px;
    margin: auto;
  }

  .infoP {
    font-size: 28px;
    margin-bottom: 20px;
    color: black;
  }

  .title {
      font-size: 80px;
      line-height: .8;
      margin-bottom: 25px;
  }

  .repositoriesDiv {
    display: flex;
    flex-wrap: wrap;
    max-width: 50%;
    min-width: 30%;
  }

  .repository {
    text-decoration: none;
    padding: 5px 15px;
    margin: 0px 5px 5px 0px;
    background-color: #FF4646;
    color: white;
    border-radius: 15px;
  }

  .userPhoto {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 767px) {
  .App {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
  }

  .backGround {
    background: radial-gradient(82.11% 50% at 82.11% 50%, #FF2A2A 0%, #FFB86A 100%);
    opacity: 0.4;
    filter: blur(76.1119px);
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
  }

  .bodyContainer {
    width: 100%;
    z-index: 2000;
  }

  .textContainer {
    padding: 15px 0px 0px 0px;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .headerTitle {
    margin-bottom: 15px;
  }

  .headerForm {
    display: flex;
    margin-bottom: 15px;
  }

  .headerForm__input {
    padding: 5px 10px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #FF4646;
    margin-right: 15px;
    color: black;
    transition: all .2s ease-in-out;
  }

  .headerForm__input:focus {
    outline: none;
    border-color: #ff0f0f;
  }

  .headerForm__button {
    padding: 10px 25px;
    background-color: #FF4646;
    border: none;
    border-radius: 25px;
    font-weight: 600;
    color: white;
  }

  .button {
    border-radius: 25px;
    padding: 10px 25px;
    background-color: #FF4646;
    transition: all .2s ease-in-out;
    cursor: pointer;
    color: white;
  }

  .button:hover {
    color: whitesmoke;
    background-color: #ff0f0f;
  }

  .infoContainer {
    padding: 25px 50px;
  }

  .infoP {
    font-size: 20px;
    margin-bottom: 20px;
    color: black;
    text-align: center;
  }

  .title {
      font-size: 50px;
      line-height: .8;
      margin-bottom: 25px;
  }

  .repositoriesDiv {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .repository {
    text-decoration: none;
    padding: 5px 15px;
    margin: 0px 5px 5px 0px;
    background-color: #FF4646;
    color: white;
    border-radius: 15px;
  }

  .userPhoto {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.DarkMode .backGround {
  background: #1d2d50;
  opacity: 1;
  filter: none;
}

.DarkMode .button {
  background-color: #1e5f74;
  color: #fcdab7;
}

.DarkMode .button:hover {
  background-color: #133b5c;
  color: #d3b79b;
}

.DarkMode p, .DarkMode h1 {
  color: #fcdab7;
}

.DarkMode .headerForm__input {
  border-color: #1e5f74;
  background-color: transparent;
  color: #fcdab7;
}

.fadeIn {
  animation: fadeIn .4s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}